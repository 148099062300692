<template>
  <div>
    <interna>
      <section id="dashboard">
        <div class="container">
          <div class="row margin-y1">
            <div class="col-1-1">
              <h4 class="text-center">
                Veja abaixo quais selos já estão disponíveis para o seu
                cadastramento:
              </h4>
            </div>
          </div>

          <div class="row my-3 selos">
            <div
              :class="{
                'col-1-1 col-md-1-5 padding-x2 padding-md-x1 padding-b2 padding-md-b0': true,
                selo: true,
                publicado: selo.Publicado == 'S',
              }"
              v-for="(selo, selo_i) in selos"
              :key="selo_i"
            >
              <img :src="require('@/assets/images/selos/' + selo.Imagem)" />

              <div class="categorias">
                <button
                  type="button"
                  :class="{
                    btn: true,
                    pendente: etapa(selo.ID, categoria.ID) == 'PENDENTE',
                    aprovado: etapa(selo.ID, categoria.ID) == 'APROVADO',
                    reprovado: etapa(selo.ID, categoria.ID) == 'REPROVADO',
                    aberto:
                      etapa(selo.ID, categoria.ID) != 'REPROVADO' &&
                      etapa(selo.ID, categoria.ID) != 'APROVADO' &&
                      etapa(selo.ID, categoria.ID) != 'PENDENTE',
                  }"
                  :style="{
                    'background-color':
                      etapa(selo.ID, categoria.ID) == 'APROVADO'
                        ? selo.Cor
                        : 'none',
                  }"
                  :disabled="selo.Publicado != 'S'"
                  v-for="(categoria, categoria_i) in categorias"
                  :key="categoria_i"
                  @click="clic(selo, categoria)"
                >
                  {{ categoria.Nome }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </interna>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Dashboard",
  },
  data: function () {
    return {
      selos: [],
      categorias: [],
    };
  },
  mounted: function () {
    //atualiza empresa no cache
    this.$sdk.get("/cnpj", { cnpj: this.$store.state.empresa.CNPJ }, (data) => {
      if (data.status == 200) {
        this.$store.commit("set_empresa", { data: data.data });
        localStorage.setItem("turismo_empresa", JSON.stringify(data.data));
      }
    });

    this.$sdk.get("/selos", {}, (res) => {
      this.selos = res.data;
    });
    this.$sdk.get("/categorias", {}, (res) => {
      this.categorias = res.data;
    });
  },
  methods: {
    clic(selo, categoria) {
      let etapa = this.etapa(selo.ID, categoria.ID);

      if (selo.Publicado == "S") {
        if (etapa == "PENDENTE") {
          this.msg_pendente();
        } else if (etapa == "REPROVADO") {
          this.msg_reprovado();
        } else {
          let router_nome = "";

          if (etapa == "APROVADO") router_nome = "download";
          else router_nome = "inscricao";

          this.$router.push({
            name: router_nome,
            params: {
              selo: selo.Link,
              categoria: categoria.Link,
            },
          });
        }
      }
    },

    etapa(selo_id, categoria_id) {
      let selos = this.$store.state.empresa.Selos;
      let ret = false;

      selos.forEach((selo) => {
        if (selo.SeloID == selo_id && selo.CategoriaID == categoria_id) {
          ret = selo.Etapa;
        }
      });

      //None	CAD=Cadastrado / SEC=Secretaria / AVL = Avaliação Final / APV = Aprovado / REP = Reprovado
      switch (ret) {
        case "CAD":
        case "SEC":
          return "PENDENTE";
        case "APV":
          return "APROVADO";
        case "REP":
          return "REPROVADO";
      }

      return ret;
    },

    acao_inscricao(selo_link, categoria_link) {
      this.$router.push("inscricao/" + selo_link + "/" + categoria_link);
    },
    acao_download(selo_link, categoria_link) {
      alert("acao_download:" + selo_link + " | " + categoria_link);
    },

    msg_pendente() {
      this.$swal({
        title: "Estamos verificando...",
        text: "Você já fez a sua inscrição para receber este Selo e esta Categoria. Estamos verificando.",
        type: "warning",
        icon: "warning",
        confirmButtonText: "Ok, Entendi.",
      });
    },

    msg_reprovado() {
      this.$swal({
        title: "Selo Recusado",
        text: "Devido a inconsistência no cumprimento dos critérios para esta Categoria, solicitamos que entre em contato com a Secretaria de Turismo (47) 3267-7067.",
        type: "warning",
        icon: "warning",
        confirmButtonText: "Ok, Entendi.",
      });
    },
  },
};
</script>

<style lang="scss">
section#dashboard {
  .selos {
    .selo * {
      cursor: wait;
    }

    img {
      max-width: 100%;
      opacity: 0.52;
    }

    .publicado {
      &.selo * {
        cursor: pointer;
      }
      img {
        opacity: 1;
      }

      .categorias {
        .btn {
          opacity: 1;

          &.aberto {
            &:hover,
            &:focus {
              background-color: darken(#d9d9d9, 10);
            }
          }
        }
      }
    }
  }
  .categorias {
    .btn {
      width: 100%;
      margin-top: 10px;
      background-color: #d9d9d9;

      border-color: #d9d9d9;
      color: #404040;
      opacity: 0.5;

      &.aprovado {
        color: #fff;
      }

      &.pendente {
        color: #fff;
        background-color: darken(#d9d9d9, 25%);
      }

      &.reprovado {
        color: #fff;
        background-color: var(--bs-danger);
      }
    }
  }
}
</style>