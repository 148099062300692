var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('interna',[_c('section',{attrs:{"id":"dashboard"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row margin-y1"},[_c('div',{staticClass:"col-1-1"},[_c('h4',{staticClass:"text-center"},[_vm._v(" Veja abaixo quais selos já estão disponíveis para o seu cadastramento: ")])])]),_c('div',{staticClass:"row my-3 selos"},_vm._l((_vm.selos),function(selo,selo_i){return _c('div',{key:selo_i,class:{
              'col-1-1 col-md-1-5 padding-x2 padding-md-x1 padding-b2 padding-md-b0': true,
              selo: true,
              publicado: selo.Publicado == 'S',
            }},[_c('img',{attrs:{"src":require('@/assets/images/selos/' + selo.Imagem)}}),_c('div',{staticClass:"categorias"},_vm._l((_vm.categorias),function(categoria,categoria_i){return _c('button',{key:categoria_i,class:{
                  btn: true,
                  pendente: _vm.etapa(selo.ID, categoria.ID) == 'PENDENTE',
                  aprovado: _vm.etapa(selo.ID, categoria.ID) == 'APROVADO',
                  reprovado: _vm.etapa(selo.ID, categoria.ID) == 'REPROVADO',
                  aberto:
                    _vm.etapa(selo.ID, categoria.ID) != 'REPROVADO' &&
                    _vm.etapa(selo.ID, categoria.ID) != 'APROVADO' &&
                    _vm.etapa(selo.ID, categoria.ID) != 'PENDENTE',
                },style:({
                  'background-color':
                    _vm.etapa(selo.ID, categoria.ID) == 'APROVADO'
                      ? selo.Cor
                      : 'none',
                }),attrs:{"type":"button","disabled":selo.Publicado != 'S'},on:{"click":function($event){return _vm.clic(selo, categoria)}}},[_vm._v(" "+_vm._s(categoria.Nome)+" ")])}),0)])}),0)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }